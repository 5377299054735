import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Raderthal: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Raderthal,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Raderthal"
          d="M550,828l5.4,34.2L510,874l-26-6l-10-6l24-18v-14l-10,2l4-42c8.5-0.1,32.1-0.5,40,2v18L550,828z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 505.0699 839.5308)"
        >
          <tspan x="0" y="0">
            Rader-
          </tspan>
          <tspan x="0" y="14.4">
            thal
          </tspan>
        </text>
      </g>
    </>
  );
});
